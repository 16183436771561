import React from 'react'
import PropTypes from 'prop-types'

// Material UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useStyles from '../../styles'
import { useTranslation } from 'react-i18next'


const Bar = ({ answers }) => {
	const { t: getTranslation } = useTranslation('develop')
	const graphValues = [
		{
			percentage: 20,
			text: getTranslation('grading.id_1'),
		},
		{
			percentage: 40,
			text: getTranslation('grading.id_2'),
		},
		{
			percentage: 60,
			text: getTranslation('grading.id_3'),
		},
		{
			percentage: 80,
			text: getTranslation('grading.id_4'),
		},
		{
			percentage: 100,
			text: getTranslation('grading.id_5'),
		},
	]
	const classes = useStyles()
	const barItems = answers.find((answersPage) => answersPage.page === 3)
	const barOne = barItems.answers.find((answer) => answer.id === 5)
	const barTwo = barItems.answers.find((answer) => answer.id === 6)
	return (
		<>
			{graphValues.map(value => {
				return (
					<Box key={value.percentage}>
						<Grid
							container
							justify='center'
							align-items='center'
							direction='row'
							className={classes.mainGrid}
						>
							<Grid
								container
								item
								direction='column'
								justify='center'
								alignItems='flex-start'
								xs={4}
								sm={2}
								className={classes.chartLabel}
							>
								<Typography variant='subtitle2'>{value.text}</Typography>
							</Grid>
							<Grid
								container
								direction='column'
								item
								justify='space-around'
								xs={8}
								sm={10}
								className={classes.gridItem}
							>
								{value.text === barOne.value ? (
									<Box
										className={classes.chartBar}
										style={{
											width: `${value.percentage}%`,
											background: '#cddc39',
											border: '1px solid #c0ca33',
										}}
									></Box>
								) : (
									<Box
										className={classes.chartBar}
										style={{
											width: `${value.percentage}%`,
										}}
									></Box>
								)}
								{value.text === barTwo.value ? (
									<Box
										className={classes.chartBar}
										style={{
											width: `${value.percentage}%`,
											background: '#ffeb3b',
											border: '1px solid #fbc02d',
										}}
									></Box>
								) : (
									<Box
										className={classes.chartBar}
										style={{
											width: `${value.percentage}%`,
										}}
									></Box>
								)}
							</Grid>
						</Grid>
					</Box>
				)
			})}
			<Grid container direction='row'>
				<Grid
					container
					item
					direction='row'
					justify='center'
					alignItems='center'
				>
					<Box className={`${classes.labelBox} ${classes.limeBox}`}></Box>
					<Typography variant='body2'>
						{getTranslation('resources_now')}
					</Typography>
				</Grid>
				<Grid
					container
					item
					direction='row'
					justify='center'
					alignItems='center'
					wrap='nowrap'
				>
					<Grid item>
						<Box className={`${classes.labelBox} ${classes.yellowBox}`}></Box>
					</Grid>
					<Grid item>
						<Typography variant='body2'>
							{getTranslation('resources_future')}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

Bar.propTypes = {
	answers: PropTypes.array
}

export default Bar
