import React, { useEffect } from 'react'
import { getAnswerByID } from './utils'
import { useForm } from './contexts/FormContext'

// Components
import Survey from './components/Survey'
import Summary from './components/Summary'
import Footer from './components/Footer'

// Material UI
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import useStyles from './styles'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Finnish } from './images/flags/fi.svg'
import { ReactComponent as English } from './images/flags/uk.svg'

const App = () => {
	const { currentPage, setCurrentPage, formSubmitted, setFormSubmitted } =
		useForm()
	const classes = useStyles()

	const { t: getTranslation, i18n } = useTranslation('questions')
	const questionSets = getTranslation('questions', { returnObjects: true })

	// Scroll to top when page is changed
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		})
	}, [currentPage])

	const firstAnswer = getAnswerByID(1, 1) // First question's value

	const handleNextPage = () => {
		// Check if 'Seuraava' needs to skip one page based on first question's answer
		firstAnswer === questionSets[0].questions[0].choices[1].text &&
		currentPage === 1
			? setCurrentPage(currentPage + 2)
			: setCurrentPage(currentPage + 1)
	}

	const handlePreviousPage = () => {
		// Check if 'Edellinen' needs to skip one page based on first question's answer
		firstAnswer === questionSets[0].questions[0].choices[1].text &&
		currentPage === 3
			? setCurrentPage(currentPage - 2)
			: setCurrentPage(currentPage - 1)
	}

	const handleFormSubmit = event => {
		event.preventDefault()
		setFormSubmitted(!formSubmitted)
	}

	const changeLanguageHandler = lang => {
		i18n.changeLanguage(lang)
	}

	return (
		<Box className={classes.mainBackground}>
			<Box pt={5}>
				{formSubmitted ? (
					<Summary handleFormSubmit={handleFormSubmit} />
				) : (
					<>
						<Box align='center' margin m={2} pt={3}>
							{
								// Hide language switch because answer is stored as text not id for some reason
								!firstAnswer && (
									<div>
										<Button
											onClick={() => changeLanguageHandler('fi')}
											size='small'
											variant='outlined'
											style={{ margin: 2 }}
										>
											Finnish <Finnish style={{ paddingLeft: 5 }} />
										</Button>
										<Button
											onClick={() => changeLanguageHandler('en')}
											size='small'
											variant='outlined'
											style={{ margin: 2 }}
										>
											English <English style={{ paddingLeft: 5 }} />
										</Button>
									</div>
								)
							}
						</Box>
						<Survey
							handleFormSubmit={handleFormSubmit}
							handleNextPage={handleNextPage}
							handlePreviousPage={handlePreviousPage}
							questionSets={questionSets}
							currentPage={currentPage}
							formSubmitted={formSubmitted}
						/>
					</>
				)}
				<Footer />
			</Box>
		</Box>
	)
}

export default App
