import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { finnish } from './fi'
import { english } from './en'
import Backend from 'i18next-http-backend'

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		/**
		 * If translation is empty, the fallbackLng translation is used.
		 * First finnish, then english.
		 */
		fallbackLng: ['fi', 'en'],
		returnEmptyString: false,
		compatibilityJSON: 'v3',
		resources: {
			fi: finnish,
			en: english,
		},
		react: {
			/** In case of suspense errors */
			useSuspense: false,
		},
	})

export default i18n
