import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from '../contexts/FormContext'
import { getAnswerByID } from '../utils'

// Summary components
import ButtonHandler from './ButtonHandler'
import ChartBars from './summaryComponents/ChartBars'
import NumberGauge from './summaryComponents/NumberGauge'
import TextGauge from './summaryComponents/TextGauge'
import StickyNote from './summaryComponents/StickyNote'
import GoalsPaper from './summaryComponents/GoalsPaper'

// Material UI
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import useStyles from '../styles'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'

// Images
import entrefox_stocks from '../images/summaryImages/entrefox_stocks.png'
import entrefox_business from '../images/summaryImages/entrefox_business.png'
import entrefox_pdf_bg from '../images/background/pdf_background.png'

import html2pdf from 'html2pdf.js'
import { useTranslation } from 'react-i18next'

// Convert date to dd.MM.YYYY format
const dateToYMD = (date) => {
	return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}

const Summary = ({ handleFormSubmit }) => {
	const { t: getTranslation } = useTranslation('develop')
	const { t: getGeneralT } = useTranslation('general')
	const classes = useStyles()
	const { formData } = useForm()

	// Get todays date
	const currentDate = dateToYMD(new Date())

	// Value for question 7 (in variable, since used in multiple places)
	const sliderValue = getAnswerByID(3, 7)

	// Check if user remembers the date he last answered the survey (Page 2 - Question 2)
	let previouslyDoneSurvey
	if (typeof getAnswerByID(2, 2) === 'object') {
		previouslyDoneSurvey = getTranslation('previously_done.id_1') + ' ' +
			dateToYMD(getAnswerByID(2, 2)) +
			'.'
	} else {
		previouslyDoneSurvey =
			getTranslation('previously_done.id_2') + '.'
	}

	const questionEight = getAnswerByID(3, 8)

	const questionNine = (answer) => {
		switch (answer) {
			case getTranslation('questionNine.id_1'):
				return getTranslation('questionNine.id_2') + ' '
			case getTranslation('questionNine.id_3'):
				return getTranslation('questionNine.id_4') + ' '
			case getTranslation('questionNine.id_5'):
				return getTranslation('questionNine.id_6') + ' '
		}
	}

	const questionEleven = (answer) => {
		switch (answer) {
			case getTranslation('questionEleven.id_1'):
				return getTranslation('questionEleven.id_2')
			case getTranslation('questionEleven.id_3'):
				return getTranslation('questionEleven.id_4')
			case getTranslation('questionEleven.id_5'):
				return getTranslation('questionEleven.id_6')
			case getTranslation('questionEleven.id_7'):
				return getTranslation('questionEleven.id_8')
			case getTranslation('questionEleven.id_9'):
				return getTranslation('questionEleven.id_10')
		}
	}

	const downloadPDF = async () => {
		// scrolling up is necessary in order for the PDF to load correctly
		await window.scrollTo({
			top: 0,
			left: 0
		})

		// Select and clone elements that are to be edited for the PDF
		const element = document.getElementById('summary').cloneNode(true)
		const lastPage = document.getElementById('last-pdf-page').cloneNode(true)

		// Style settings for cloned elements
		// PDF page size: [215.9mm x 279.4mm]
		lastPage.style.height = '972px'
		element.style.backgroundImage = `url(${entrefox_pdf_bg})`
		element.style.backgroundSize = '100% 279.4mm'
		element.style.backgroundRepeat = 'repeat-y'
		element.style.padding = '15px 100px 0px 100px'

		// Options for the html2pdf rendering
		const opt = {
			filename: 'entrefox_summary.pdf',
			image: { type: 'jpeg' },
			html2canvas: {
				scale: 2,
				scrollX: -window.scrollX,
				scrollY: -window.scrollY,
				windowWidth: document.documentElement.offsetWidth,
				windowHeight: document.documentElement.offsetHeight
			},
			jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
			pagebreak: { mode: ['avoid-all', 'css', 'legacy', 'whiteline'] }
		}

		// Generate the PDF from the defined options
		window.open(await html2pdf().from(element).set(opt).output('bloburl'))

		// Change the padding back after PDF has been generated
		lastPage.style.height = 'auto'
		element.style.padding = ''
		element.style.backgroundImage = ''
		element.style.backgroundSize = ''
	}

	return (
		<Container className={classes.survey} maxWidth='md'>
			<ButtonHandler
				text={getGeneralT('go_back')}
				colors={{ bg: '#cddc39', bgHover: '#c0ca33' }}
				handlePagination={handleFormSubmit}
			/>
			<Box id='summary'>
				{/* Header with EntreFox logo */}
				<Box my={5}>
					<Typography
						component='h1'
						variant='h3'
						color='primary'
						align='center'
					>
						{getTranslation('summary_title.entrepreneur')}
					</Typography>
					<Typography variant='h4' component='h1' align='center' gutterBottom>
						{getTranslation('summary_title.summary')}
					</Typography>
					<Box align='center'>
						<img src={entrefox_business} className={classes.headingImage} />
					</Box>
					<Typography variant='h6' align='center'>
						{getGeneralT('form_done_on')} {currentDate}.
					</Typography>
				</Box>
				<Divider />

				{/* Manual page-break for the PDF generation */}
				<div className='html2pdf__page-break'></div>

				{/* Page 3: Questions ID 5 - 11 */}
				<Box mt={10} mb={5}>
					<Typography variant='h5' className={classes.heading}>
						{getTranslation('answers.page_3.id_1')}
					</Typography>
					{/* Questions 5-6 */}
					<Typography variant='body1'>
						{getTranslation('answers.page_3.id_2')}{' '}
						{getAnswerByID(3, 5).toLowerCase()}{' '}
						{getTranslation('answers.page_3.id_3')}{' '}
						{getAnswerByID(3, 6).toLowerCase()}
						{getTranslation('answers.page_3.id_4')}
					</Typography>
					{/* Chart for questions */}
					<Box mt={3}>
						<ChartBars answers={formData} />
					</Box>
				</Box>

				{/* Manual page-break for the PDF generation */}
				<div className='html2pdf__page-break'></div>

				{/* Question 7 */}
				<Box my={10}>
					<Typography variant='body1'>
						{getTranslation('answers.page_3.question_7.id_1')} {sliderValue}.{' '}
						{getTranslation('answers.page_3.question_7.id_2')}{' '}
						<a
							className={classes.linkTag}
							target='blank'
							href='https://www.entrefox.fi/terveyskunto-ja-sen-kehittaminen/'
						>
							{getTranslation('answers.page_3.question_7.id_3')}
						</a>
						, {getTranslation('answers.page_3.question_7.id_4')}
					</Typography>
					<Box mt={5}>
						{/* Gauge for question 7 */}
						<NumberGauge answer={sliderValue} />
					</Box>
				</Box>

				{/* Question 8 - 9 */}
				<Box mt={10}>
					<Typography variant='body1'>
						{getTranslation('answers.page_3.question_8_9.id_1')}{' '}
						<i>
							{questionEight.toLowerCase().split(' ')[0]}{' '}
							{getTranslation('answers.page_3.question_8_9.id_2')}
						</i>
						. {questionNine(getAnswerByID(3, 9))}
						{getTranslation('answers.page_3.question_8_9.id_3')}{' '}
						<a
							className={classes.linkTag}
							target='blank'
							href='https://www.entrefox.fi/ajanhallinta/'
						>
							{getTranslation('answers.page_3.question_8_9.id_4')}
						</a>
						.
					</Typography>
					<Box mt={5}>
						<TextGauge answer={questionEight} />
					</Box>
				</Box>

				{/* Manual page-break for the PDF generation */}
				<div className='html2pdf__page-break'></div>

				{/* Question 11 */}
				<Box mt={10} mb={2}>
					<Typography variant='body1'>
						{getTranslation('answers.page_3.question_11.id_1')}{' '}
						<Box component='span' fontStyle='italic'>
							{questionEleven(getAnswerByID(3, 11))}
						</Box>{' '}
						<a
							className={classes.linkTag}
							target='blank'
							href='https://www.entrefox.fi/yrittajan-osaamiskartoitus/'
						>
							{getTranslation('answers.page_3.question_11.id_2')}
						</a>{' '}
						{getTranslation('answers.page_3.question_11.id_3')}
					</Typography>
				</Box>
				{/* Question 10 */}
				<Box mb={10}>
					<Typography variant='body1'>
						{getTranslation('answers.page_3.question_11.id_4')}:
					</Typography>
					<Box my={4}>
						<StickyNote answer={getAnswerByID(3, 10)} />
					</Box>
				</Box>

				{/* Manual page-break for the PDF generation */}
				<div className='html2pdf__page-break'></div>

				{/* Element wont be visible on the PDF */}
				<Divider data-html2canvas-ignore='true' />

				{/* Page 4: Question 12 (Possible multiple fields in one question) */}
				<Box my={10}>
					<Box mb={3}>
						<Typography variant='h5' className={classes.heading}>
							{getTranslation('answers.page_4.id_1')}
						</Typography>
					</Box>
					<Box mb={10}>
						<Typography variant='body1'>
							{getTranslation('answers.page_4.id_2')}
						</Typography>
					</Box>
					{/* Paper with EntreFox badge including fields in question 12 */}
					<GoalsPaper answers={getAnswerByID(4, 12)} />
				</Box>

				{/* Element wont be visible on the PDF */}
				<Divider data-html2canvas-ignore='true' />

				{/* Manual page-break for the PDF generation */}
				<div className='html2pdf__page-break'></div>
				<Box id='last-pdf-page'>
					{/* No questions - info text with image */}
					<Box my={10}>
						<Box mb={3}>
							<Typography variant='h5' className={classes.heading}>
								{getTranslation('answers.page_4.no_questions.id_1')}
							</Typography>
						</Box>
						<Grid
							container
							direction='row'
							justify='space-around'
							alignItems='flex-start'
						>
							<Grid item xs={8} md={10}>
								<Typography variant='body1'>
									{getTranslation('answers.page_4.no_questions.id_2')}
								</Typography>
							</Grid>
							<Grid item xs={4} md={2}>
								<Box align='center'>
									<img
										className={classes.summaryImage}
										src={entrefox_stocks}
										alt={getTranslation('answers.page_4.no_questions.id_3')}
									/>
								</Box>
							</Grid>
						</Grid>
					</Box>

					{/* Element wont be visible on the PDF */}
					<Divider data-html2canvas-ignore='true' />

					{/* Extra part of the survey - ONLY IF USER ANSWERED YES TO FIRST QUESTION */}
					{/* Page 2 - Questions 2-4 */}
					{getAnswerByID(1, 1) === getTranslation('answers.page_2.id_1') ? (
						<Box my={10}>
							<Box mb={3}>
								<Typography variant='h5' className={classes.heading}>
									{getTranslation('answers.page_2.id_2')}
								</Typography>
							</Box>
							<Typography variant='body1'>
								{previouslyDoneSurvey} <br />
							</Typography>
							<Box mt={2}>
								<Typography variant='h6'>
									{' '}
									{getTranslation('answers.page_2.id_3')}:
								</Typography>
							</Box>

							<Box className={classes.textBorder} mb={2}>
								<Typography variant='body1'>{getAnswerByID(2, 3)}</Typography>
							</Box>

							<Typography variant='h6'>
								{getTranslation('answers.page_2.id_4')}:
							</Typography>
							<Box>
								<Typography variant='body1'>{getAnswerByID(2, 4)}</Typography>
							</Box>
						</Box>
					) : null}
				</Box>
				{/* Element wont be visible on the PDF */}
				<Divider data-html2canvas-ignore='true' />
			</Box>
			<Box mt={2}>
				<Grid
					container
					direction='row'
					justify='space-between'
					alignItems='center'
				>
					<Grid item xs={12} sm={3}>
						<Box>
							<ButtonHandler
								text={getGeneralT('download_pdf')}
								colors={{ bg: '#cddc39', bgHover: '#c0ca33' }}
								startIcon={<GetAppRoundedIcon />}
								handlePagination={downloadPDF}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Box mt={2} mb={1}>
							<ButtonHandler
								href='https://www.entrefox.fi/kehityskeskustelu/'
								text={getGeneralT('end_form')}
								startIcon={<CheckCircleOutlineRoundedIcon />}
								colors={{
									bg: '#ffeb3b',
									bgHover: '#fbc02d',
								}}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}

Summary.propTypes = {
	handleFormSubmit: PropTypes.func
}

export default Summary
