import React from 'react'
import PropTypes from 'prop-types'

// Material UI
import useStyles from '../../styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

// Images
import entrefox_badge from '../../images/summaryImages/entrefox_badge.png'
import entrefox_steps from '../../images/summaryImages/entrefox_steps.png'
import { useTranslation } from 'react-i18next'

const GoalsScroll = ({ answers }) => {
	const classes = useStyles()
	const { t: getTranslation } = useTranslation('develop')

	return answers.map((answer, index) => {
		return (
			<Grid
				key={answer.ID}
				container
				className={classes.goalBox}
				justify='center'
				direction='column'
			>
				<Grid container direction='row' item alignItems='center'>
					<Grid item xs={12} md={5}>
						<Box className={classes.badge}>
							<img src={entrefox_badge} />
						</Box>
					</Grid>
					<Grid item>
						<Box mx={2} my={1}>
							<Typography variant='h4'>
								{index + 1}. {getTranslation('objective')}
							</Typography>
						</Box>
					</Grid>
				</Grid>
				<Grid item>
					<Box mx={2} my={1}>
						<Typography
							variant='body1'
							className={classes.noteText}
							key={answers.ID}
						>
							<Box component='span' fontWeight='bold'>
								{getTranslation('objective')}:
							</Box>{' '}
							{answer.values[0].value}
						</Typography>
					</Box>
				</Grid>
				<Grid item>
					<Box mx={2} my={1}>
						<Typography
							variant='body1'
							className={classes.noteText}
							key={answers.ID}
						>
							<Box component='span' fontWeight='bold'>
								{getTranslation('objective_important')}:
							</Box>{' '}
							{answer.values[1].value}
						</Typography>
					</Box>
				</Grid>
				<Grid item>
					<Box mx={2} my={1}>
						<Typography
							variant='body1'
							className={classes.noteText}
							key={answers.ID}
						>
							<Box component='span' fontWeight='bold'>
								{getTranslation('steps_to_objective')}:
							</Box>{' '}
							{answer.values[2].value}
						</Typography>
					</Box>
				</Grid>

				<Grid item>
					<Box align='center'>
						<img
							className={classes.summaryImage}
							src={entrefox_steps}
							alt={getTranslation('img_alt')}
						/>
					</Box>
				</Grid>
			</Grid>
		)
	})
}

GoalsScroll.propTypes = {
	answers: PropTypes.array
}

export default GoalsScroll
