import React from 'react'
import PropTypes from 'prop-types'

// Material UI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useStyles from '../../styles'
import { useTranslation } from 'react-i18next'

const TextGauge = ({ answer }) => {
	const classes = useStyles()
	const { t: getTranslation } = useTranslation('develop')

	// Fill the gauge and turn the needle based on answer value
	const gaugeValues = () => {
		switch (answer) {
			case getTranslation('grading.id_1'):
				return { fill: 0.1, needle: -0.16 }
			case getTranslation('grading.id_2'):
				return { fill: 0.2, needle: -0.06 }
			case getTranslation('grading.id_3'):
				return { fill: 0.25, needle: 0 }
			case getTranslation('grading.id_4'):
				return { fill: 0.4, needle: 0.15 }
			case getTranslation('grading.id_5'):
				return { fill: 0.5, needle: 0.25 }
		}
	}
	return (
		<>
			<Box textAlign='center' my={2}>
				<Typography variant='h6'>
					{getTranslation('life_ratio')}
				</Typography>
			</Box>
			<Box className={classes.gauge}>
				<Box className={classes.gaugeBody}>
					<Box
						className={classes.gaugeFill}
						style={{
							transform: `rotate(${gaugeValues().fill}turn)`,
						}}
					></Box>
					<Box className={classes.gaugeCover}></Box>
					<Box
						style={{
							transform: `rotate(${gaugeValues().needle}turn)`,
						}}
						className={classes.gaugeNeedle}
					></Box>
				</Box>
				<Box align='center'>
					<Typography variant='h6'>{answer}</Typography>
				</Box>
			</Box>
		</>
	)
}

TextGauge.propTypes = {
	answer: PropTypes.string
}

export default TextGauge
